/* ----------------------------------------------------------------------------
closeable banner bar that remembers if it's been closed
---------------------------------------------------------------------------- */

export default class BannerBar extends HTMLElement {
  closeable() {
    this.closeBtn.addEventListener('click', (e) => {
      this.closeBanner();
    });

    this.links.forEach(link => {
      link.addEventListener('click', (e) => {
        this.closeBanner();
      });
    });
  }

  closeBanner() {
    localStorage.setItem('banner-closed', this.bannerKey);
    document.documentElement.classList.add('banner-closed');
  }

  connectedCallback() {
    this.closeBtn = this.querySelector('button');
    this.links = this.querySelectorAll('a');
    this.bannerKey = this.id;
    this.closeable();
  }
}

window.customElements.define('banner-bar', BannerBar);